import {PracticeType} from "../../models/Practice/Practice";
import {
    Col, Form, FormProps, Input,
    InputNumber, Modal, Row, Space,
} from "antd";
import React, {useState} from "react";
import {RefundModuleFormData} from "../../models/refundModule";
import {DateField} from "../CambioVisus/DateField";

export interface RefundModuleDialogProps {
    isModalOpen: boolean,
    onSubmit: (body: RefundModuleFormData) => Promise<void>;
    onCancel: () => void,
    agreement: PracticeType,
    integrationCode: string
    insuranceProviderId: number,
    locationCode: string;
}

const FormItem = Form.Item<RefundModuleFormData>;

export function RefundModuleDialog(props: RefundModuleDialogProps) {

    const [form] = Form.useForm();

    const [isSubmitting, setSubmitting] = useState(false);

    const onCancel = () => {
        form.resetFields();
        props.onCancel();
    }

    const onSubmit: FormProps<RefundModuleFormData>['onFinish'] = (data) => {
        props.onSubmit(data);
    };

    const onFinish = async (data: RefundModuleFormData) => {
        setSubmitting(true)
        try {
            await props.onSubmit(data);
            form.resetFields();
        } finally {
            setSubmitting(false);
        }
    }

    const parseValue = (value: string | undefined): number => {
        if (!value) return 0; // Restituisce 0 se il valore è vuoto
        const parsedValue = value.replace(',', '.'); // Sostituisce la virgola con il punto
        const numberValue = Number(parsedValue);
        if (isNaN(numberValue) || numberValue < 0) return 0; // Valida il valore positivo
        return numberValue;
    };

    const modalRender = (dom: any) => {
        return <Form layout="vertical"
                     form={form}
                     name="preventiveDialog"
                     onFinish={onFinish}
                     initialValues={{orders: [{}]}}
        >{dom}</Form>
    }

    const key = "moduloRimborso" + props.integrationCode;

    return <>
        <Modal width={820}
               closable
               open={props.isModalOpen}
               title="Modulo per richiesta rimborso pratiche indirette"
               okText="Ok"
               cancelText="Annulla"
               okButtonProps={{loading: isSubmitting, autoFocus: true, htmlType: 'submit'}}
               destroyOnClose
               onCancel={onCancel}
               modalRender={modalRender}
        >
            <div>
                <Form
                    form={form}
                    scrollToFirstError
                    key={key}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Space direction="vertical" size="middle" style={{width: "100%"}}>
                        <div>
                            <FormItem
                                name="invoiceNumber"
                                label="Riferimento Fattura"
                                rules={[
                                    {
                                        required: true,
                                        message: "Inserisci il riferimento della fattura",
                                    },
                                ]}
                            >
                                <Input/>
                            </FormItem>
                            <FormItem
                                name="invoiceDate"
                                label="Data"
                                rules={[
                                    {
                                        required: true,
                                        message: "Selezionare una data",
                                    },
                                ]}
                            >
                                <DateField/>
                            </FormItem>

                            <Row>
                                <Col span={12}>
                                    <FormItem name="art1" label="Montatura">
                                        <Input/>
                                    </FormItem>
                                </Col>
                                <Col style={{ marginLeft: '10px' }} span={9}>
                                    <FormItem name="art1amount" label="Importo" >
                                        <InputNumber step={0.1} precision={2}
                                                     formatter={(value) => (value ? value.toString().replace('.', ',') : '')}
                                                     parser={(value) => parseValue(value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <FormItem name="art2" label="Lenti Oftalmiche">
                                        <Input/>
                                    </FormItem>
                                </Col>
                                <Col style={{ marginLeft: '10px' }} span={9}>
                                    <FormItem name="art2amount" label="Importo">
                                        <InputNumber step={0.1} precision={2}
                                                     formatter={(value) => (value ? value.toString().replace('.', ',') : '')}
                                                     parser={(value) => parseValue(value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <FormItem name="art3" label="Lenti a Contatto">
                                        <Input/>
                                    </FormItem>
                                </Col>
                                <Col style={{ marginLeft: '10px' }} span={9}>
                                    <FormItem name="art3amount" label="Importo">
                                        <InputNumber step={0.1} precision={2}
                                                     formatter={(value) => (value ? value.toString().replace('.', ',') : '')}
                                                     parser={(value) => parseValue(value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <FormItem name="art4" label="Altro">
                                        <Input/>
                                    </FormItem>
                                </Col>
                                <Col style={{ marginLeft: '10px' }} span={9}>
                                    <FormItem
                                        name="art4amount"
                                        label="Importo"
                                    >
                                        <InputNumber step={0.1} precision={2}
                                                     formatter={(value) => (value ? value.toString().replace('.', ',') : '')}
                                                     parser={(value) => parseValue(value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    </Space>
                </Form>
            </div>
        </Modal>
    </>
}
