import {Patient} from "../../models/Patient";
import {InsuranceProviderEclaim, InsuranceProviderIntegrationCodes} from "../../models/InsuranceProvider";
import {CambioVisusGeneric} from "./CambioVisusGeneric";
import {CambioVisusPrevimedical} from "./CambioVisusPrevimedical";
import {CambioVisusFormData, UploadCambioVisusRequest} from "../../models/cambioVisus";
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {NotificationService} from "../../services/NotificationService";
import {useParams} from "react-router-dom";
import {useState} from "react";

export interface CambioVisusProps {
  insuranceProvider: InsuranceProviderEclaim,
  onClose: ()=>void;
  patient?: Patient
  shopGroup: string;
  onSubmit: (data: CambioVisusFormData) => Promise<void>;
}

export function CambioVisus(props: CambioVisusProps) {
  const integrationCode = props.insuranceProvider.integrationCode;

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (data: CambioVisusFormData) => {
    setSubmitting(true)
    try {
      await props.onSubmit(data)
      props.onClose();
    } finally {
      setSubmitting(false);
    }
  }

  switch (integrationCode) {
    case InsuranceProviderIntegrationCodes.PREVIMEDICAL:
      return <CambioVisusPrevimedical shopGroup={props.shopGroup}
                                      onSubmit={onSubmit}
                                      isSubmitting={isSubmitting}
                                      insuranceProviderId={props.insuranceProvider.id}
                                      onClose={props.onClose}
                                      patient={props.patient}/>
    default:
      return <CambioVisusGeneric shopGroup={props.shopGroup}
                                 onSubmit={onSubmit}
                                 isSubmitting={isSubmitting}
                                 insuranceProviderIntegrationCode={integrationCode}
                                 onClose={props.onClose}
                                 patient={props.patient}/>
  }
}
