import {Button, Modal} from "antd";
import {useModal} from "../../../utils/modals";
import {MutableRefObject, useEffect, useRef, useState} from "react";
import { CameraEnhancer, CameraView, QuadDrawingItem, ImageEditorView } from "dynamsoft-camera-enhancer";
import {
    CapturedResultReceiver,
    CaptureVisionRouter,
    SimplifiedCaptureVisionSettings
} from "dynamsoft-capture-vision-router";
import {
    CapturedResultItem,
    DSImageData,
    EnumCapturedResultItemType,
    OriginalImageResultItem,
    Point
} from "dynamsoft-core";
import {NormalizedImageResultItem} from "dynamsoft-document-normalizer";

export interface ScanDocumentProps {
    onChange: (photoDataUri: string, scan: boolean) => void;
}

export function ScanDocument(props: ScanDocumentProps) {

    const {
        isOpen,
        open,
        close
    } = useModal();

    const onChange = (photoUri: string) => {
        props.onChange(photoUri, true);
        close();
    }

    return <>
        <Button type="primary" onClick={open}>Scansiona documento</Button>
        <Modal
            width={820}
            closable={false}
            destroyOnClose
            open={isOpen}
            onCancel={close} footer={() => <></>}>
            <Camera onChange={onChange}/>
        </Modal>
    </>
}

function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result as string);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
    });
}

function Camera(props: { onChange: (photoUri: string) => void }) {
    const imageEditorViewContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const cameraViewContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const cameraEnhancer: MutableRefObject<Promise<CameraEnhancer> | null> = useRef(null);
    const router: MutableRefObject<Promise<CaptureVisionRouter> | null> = useRef(null);

    const [bShowUiContainer, setShowUiContainer] = useState(true);
    const [bShowImageContainer, setShowImageContainer] = useState(false);
    const [showButtonTakePhoto, setShowButtonTakePhoto] = useState(true);
    const [showButtonConfirm, setShowButtonConfirm] = useState(false);
    const [bShowLoading, setShowLoading] = useState(true);

    const observer = useRef<MutationObserver | null>(null);

    const normalizer: MutableRefObject<CaptureVisionRouter | null> = useRef(null);
    const dce: MutableRefObject<CameraEnhancer | null> = useRef(null);
    const imageEditorView: MutableRefObject<ImageEditorView | null> = useRef(null);
    const layer: MutableRefObject<any> = useRef(null);
    const view: MutableRefObject<CameraView | null> = useRef(null);
    const items: MutableRefObject<Array<any>> = useRef([]);
    const image: MutableRefObject<DSImageData | null> = useRef(null);

    useEffect((): any => {
        init();

        return async () => {
            (await router.current)?.dispose();
            (await cameraEnhancer.current)?.dispose();
            view.current?.dispose();
            imageEditorView.current?.dispose();
        }
    }, []);

    const init = async () => {
        try {
            view.current = await CameraView.createInstance();
            view.current.setScanLaserVisible(false)
            view.current.setScanRegionMaskVisible(false)
            view.current.setTipVisible(false)
            dce.current = await (cameraEnhancer.current = CameraEnhancer.createInstance(view.current));
            /**
             * Creates a CaptureVisionRouter instance and configure the task to detect document boundaries.
             * Also, make sure the original image is returned after it has been processed.
             */
            normalizer.current = await (router.current = CaptureVisionRouter.createInstance());
            normalizer.current.setInput(dce.current);
            /**
             * Sets the result types to be returned.
             * Because we need to normalize the original image later, here we set the return result type to
             * include both the quadrilateral and original image data.
             */
            let newSettings = await normalizer.current.getSimplifiedSettings("DetectAndNormalizeDocument_Default");
            newSettings.capturedResultItemTypes |= EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE;
            await normalizer.current.updateSettings("DetectDocumentBoundaries_Default", newSettings);
            cameraViewContainerRef.current!.append(view.current.getUIElement());
            /* Defines the result receiver for the task.*/
            const resultReceiver = new CapturedResultReceiver();
            resultReceiver.onCapturedResultReceived = (result) => {
                const originalImage = result.items.filter((item: CapturedResultItem) => { return item.type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE });
                if (originalImage.length) {
                    const originalImageElement = originalImage[0] as OriginalImageResultItem;
                    image.current = originalImageElement.imageData;
                }
                items.current = result.items.filter((item: CapturedResultItem) => { return item.type === EnumCapturedResultItemType.CRIT_DETECTED_QUAD });
            }
            normalizer.current.addResultReceiver(resultReceiver);
            await dce.current.open();
            /* Uses the built-in template "DetectDocumentBoundaries_Default" to start a continuous boundary detection task. */
            await normalizer.current.startCapturing("DetectDocumentBoundaries_Default");
            setShowLoading(false);
        } catch (ex: any) {
            let errMsg = ex.message || ex;
            console.error(errMsg);
            alert(errMsg);
        }
    }

    const confirmTheBoundary = async () => {
        if (!dce.current!.isOpen() || !items.current.length) return;
        /* Hides the cameraView and shows the imageEditorView. */
        setShowUiContainer(false);
        setShowImageContainer(true);
        imageEditorView.current = await ImageEditorView.createInstance(imageEditorViewContainerRef.current as HTMLDivElement);
        /* Creates an image editing layer for drawing found document boundaries. */
        layer.current = imageEditorView.current.createDrawingLayer();
        /* Draws the image on the imageEditorView first. */
        imageEditorView.current!.setOriginalImage(image.current!);
        const quads: Array<any> = [];
        /* Draws the document boundary (quad) over the image. */
        for (let i = 0; i < items.current.length; i++) {
            if (items.current[i].type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE) continue;
            const points = items.current[i].location.points;
            const quad = new QuadDrawingItem({ points });
            quads.push(quad);
            layer.current!.addDrawingItems(quads);
        }

        const targetNode = document.getElementsByTagName("body").item(0);

        const config = {childList: true};
        observer.current = new MutationObserver(mutations => {
            for (const mutation of mutations) {
                const addedNodes = Array.from(mutation.addedNodes.values());
                for (let addedNode of addedNodes) {
                    if (addedNode instanceof HTMLCanvasElement) {
                        addedNode.style.zIndex = '3000';
                    }
                }
            }
        })

        observer.current.observe(targetNode!, config);

        setShowButtonTakePhoto(false);
        setShowButtonConfirm(true);
        normalizer.current!.stopCapturing();
    }

    const normalize = async () => {
        if (observer.current != null) {
            observer.current.disconnect();
        }

        /* Get the selected quadrilateral */
        const seletedItems = imageEditorView.current!.getSelectedDrawingItems();
        let quad;
        if (seletedItems.length) {
            quad = (seletedItems[0] as QuadDrawingItem).getQuad();
        } else {
            quad = items.current[0].location;
        }
        const isPointOverBoundary = (point: Point) => {
            return point.x < 0 ||
                point.x > image.current!.width ||
                point.y < 0 ||
                point.y > image.current!.height;
        };
        /* Check if the points beyond the boundaries of the image. */
        if (quad.points.some((point: Point) => isPointOverBoundary(point))) {
            alert("Il contorno del documento esce dai contorni dell'immagine!");
            return;
        }

        /* Hides the imageEditorView. */
        setShowImageContainer(false);
        /**
         * Sets the coordinates of the ROI (region of interest)
         * in the built-in template "normalize-document".
         */
        let newSettings = await normalizer.current!.getSimplifiedSettings("normalize-document") as SimplifiedCaptureVisionSettings;
        newSettings.roiMeasuredInPercentage = false;
        newSettings.roi.points = quad.points;
        await normalizer.current!.updateSettings("normalize-document", newSettings);
        /* Executes the normalization and shows the result on the page */
        let norRes = await normalizer.current!.capture(image.current!, "normalize-document");
        if (norRes.items[0]) {
            const item = norRes.items[0] as NormalizedImageResultItem;
            const blob = await item.toBlob("image/png");
            const imageUrl = await blobToDataURL(blob);
            props.onChange(imageUrl);
        }
        layer.current!.clearDrawingItems();
        setShowButtonTakePhoto(false);
        setShowButtonConfirm(false);
        /* show video view */
        // setShowUiContainer(true);
        view.current!.getUIElement().style.display = "";
        // await normalizer.current!.startCapturing("DetectDocumentBoundaries_Default");
    }

    return <div className="container">
        <div id="div-loading" style={{display: bShowLoading ? "block" : "none"}}>Loading...</div>
        <div id="div-video-btns">
            { showButtonTakePhoto && <Button type={"primary"} onClick={confirmTheBoundary}>Scatta foto</Button> }
            { showButtonConfirm && <Button type={"primary"} onClick={normalize}>Conferma bordi</Button>}
        </div>
        <div style={{display: bShowUiContainer ? "block" : "none", marginTop: "10px", height: "500px"}}
             ref={cameraViewContainerRef}></div>
        <div style={{display: bShowImageContainer ? "block" : "none", height: "70vh"}}
             ref={imageEditorViewContainerRef}>
            <div className="dce-image-container" style={{width: "100%", height: "100%"}}></div>
        </div>
    </div>
}