import {Button} from "antd";
import {PracticeType} from "../../models/Practice/Practice";
import {useModal} from "../../utils/modals";
import {RefundModuleFormData} from "../../models/refundModule";
import {RefundModuleDialog} from "./RefundModuleDialog";

export interface AccompagnatoriaDialogTriggerProps {
    disabled?: boolean;
    isLoaded: boolean;
    onSubmit: (body: RefundModuleFormData) => Promise<void>;
    agreement: PracticeType;
    integrationCode:string
    insuranceProviderId:number,
    locationCode: string;
}

export function RefundModuleDialogTrigger(props: AccompagnatoriaDialogTriggerProps) {

    const {
        isOpen,
        open,
        close
    } = useModal()

    const openModal = () => {
        if (!props.isLoaded) {
            open();
        }
    }

    const onSubmit = async (body: RefundModuleFormData) => {
        await props.onSubmit(body);
        close();
    }

    const onCancel = () => {
        close();
    }

    return <>
        <Button disabled={props.disabled} type={"primary"} className={!props.disabled && props.isLoaded ? "luxottica-button-success" : ""} onClick={openModal}>Modulo per richiesta rimborso pratiche indirette</Button>
        <RefundModuleDialog isModalOpen={isOpen} onSubmit={onSubmit} onCancel={onCancel}
                            agreement={props.agreement}
                            integrationCode={props.integrationCode}
                            insuranceProviderId={props.insuranceProviderId}
                            locationCode={props.locationCode}
        />
    </>
}